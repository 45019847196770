<template>
  <div class="AppSourceReport">
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" label-width="80px" size="mini">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :span="6" style="height: 34px;">
              <el-form-item label="Date:" label-width="40px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-date-picker
                      v-model="filter.date"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="Start Date"
                      end-placeholder="End Date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :clearable="false"
                      class="w100"
                    ></el-date-picker>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox v-model="filter.columns" class="color-white" label="date"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="clickSourceApp(s):" label-width="120px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input v-model="filter.clickSourceApps" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      v-model="filterCheckedStatus"
                      label="source_app"
                      class="color-white"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Offer(s):" label-width="60px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model="filter.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="offer_id"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Countries(s):" label-width="90px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input v-model="filter.countries" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox v-model="filterCheckedStatus" label="country" class="color-white"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Package(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model="filter.pkgs"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="prod"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="adx(s):" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.adxs"
                      :data-source="options.adxOptions"
                      multiple
                      reserve-keyword
                      collapse-tags
                      clearable
                      filterable
                      placeholder="Please select"
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="adx"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="24" align="left">
              <el-form-item label="Group by" label-width="90px" class="mb5">
                <el-col :lg="20" :md="20">
                  <el-checkbox v-model="filterCheckedStatus" label="event_name"></el-checkbox>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0px" class="mb0">
                <el-button
                  type="primary"
                  :loading="loading.search"
                  @click="filterDataSet('Search', 1)"
                  >Search</el-button
                >
                <el-button type="primary" @click="filterDataSet('Download')">Download</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="trackSourceTableRef"
          style="width:100%;"
          size="mini"
          border
          highlight-current-row
          :row-class-name="tableRowClassName"
          @sort-change="sortChange"
        >
          <el-table-column
            label="date"
            prop="date"
            show-overflow-tooltip
            sortable="custom"
            v-if="checkDate('date') || checkDate('hour')"
          ></el-table-column>
          <el-table-column
            v-if="checkIn('offer_id')"
            label="offerId "
            prop="offerId"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="clicks"
            prop="clickCount"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="CVR"
            prop="formatCvr"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column
            v-if="checkIn('prod')"
            label="pkg"
            prop="prod"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="install"
            prop="install"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="events"
            prop="events"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="rejectCount"
            prop="rejectionTotal"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="clickSourceApp"
            prop="sourceApp"
            show-overflow-tooltip
            v-if="checkIn('source_app')"
          ></el-table-column>
          <el-table-column
            v-if="checkIn('event_name')"
            label="EventName"
            prop="eventName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('country')"
            label="country"
            prop="country"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('adx')"
            label="adx"
            prop="adx"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="pages.total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pages.pageParam.pageSize"
          :currentPage="pages.pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import TrackSourceConv2 from '../controllers/report/AppSourceReport';
  export default {
    name: 'AppSourceReport',
    ...TrackSourceConv2,
  };
</script>

<style lang="scss" scoped></style>
